import _ from 'lodash';
import Model from 'utils/Model';

class CustomFieldModel extends Model {
    initialize(props) {
        super.initialize(props);
        this.name = props.name || null;
        this.code = props.code ? _.camelCase(props.code) : null;
        this.comment = props.comment || null;
        this.value = props.value || null;
        this.type = props.type || null;
        this.tags = props.tags || [];
        this.groupId = props.group ? props.group.id : null;
        this.groupName = props.group ? props.group.name : null;
        this.field = props.field || {};
        this.behaviors = props.behaviors || [];
        this.viewClasses = props.viewClasses || [];
    }

    getDefaultValue(referenceModel) {
        let value = undefined;
        for (let key in this.behaviors) {
            if (!this.behaviors.hasOwnProperty(key) || this.behaviors[key] === undefined) continue;
            const behavior = this.behaviors[key];
            if (behavior.type === 'get_value') {
                value = referenceModel.deepFind(behavior.data.path);
                break;
            }
        }
        return value || this.field.defaultValue || null;
    }
}

export default CustomFieldModel;
