import Model from '../../../../utils/Model';

class OrderStatusModel extends Model {
    static validators = {};

    initialize(props) {
        super.initialize(props);
        this.id = props.id;
        this.name = props.name || '';
        this.code = props.code || '';
        this.isDeleted = !!props.isDeleted;
    }
}

export default OrderStatusModel;
