import Button from 'common/ui/markup/Button';
import Modal from 'common/ui/modal/Modal';
import { useEffect, useState } from 'react';
import { t } from 'translations';
import useConfig from '../../../../utils/hooks/useConfig';
import WebPushService from '../service';

const WebPushSubscriber = () => {
    const [showModal, setShowModal] = useState(false);
    const config = useConfig();
    const vapidKey = config.remote.webPush.publicKey;
    const enabled = config.remote.webPush.enabled;

    const available = vapidKey && enabled;

    const handleWebPushSub = async () => {
        setShowModal(false);
        if (!available) {
            return;
        }
        if (!WebPushService.hasPermission()) {
            await WebPushService.requestPermission();
        }
        let subscription = await WebPushService.getSubscription();
        if (!subscription) {
            subscription = await WebPushService.subscribe(vapidKey);
        }
        const json = subscription?.toJSON() ?? null;
        if (json === null) {
            console.error('Failed to get subscription');
        } else {
            await WebPushService.storeSubscription(subscription.toJSON());
        }
    };

    useEffect(() => {
        if (!available) {
            return;
        }
        if (!WebPushService.hasPermission()) {
            if (WebPushService.isDefaultPermission()) {
                WebPushService.getSubscription().then((sub) => {
                    if (!sub) {
                        setShowModal(true);
                    }
                });
            }
            return;
        }
        const updateSubscription = async () => {
            const subscription = await WebPushService.getSubscription();
            if (subscription) {
                await WebPushService.storeSubscription(subscription.toJSON());
            }
        };

        updateSubscription();
    }, []);

    return (
        <>
            <Modal shown={showModal}>
                <Modal.Header>{t('notifications.pleaseEnable')}</Modal.Header>
                <Modal.Body>{t('notifications.popupDescriptions')}</Modal.Body>
                <Modal.Footer>
                    <Button small primary onClick={handleWebPushSub} type="submit">
                        {t('notifications.enable')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default WebPushSubscriber;
