export default {
    searchRegion: {
        en: 'Search region...',
        ru: 'Поиск региона...',
    },
    name: {
        en: 'Name',
        ru: 'Название',
    },
    saved: {
        en: 'Region saved',
        ru: 'Регион сохранён',
    },
    created: {
        en: 'Region created',
        ru: 'Регион создан',
    },
    deleteConfirmation: {
        en: 'Are you sure want to delete region?',
        ru: 'Вы уверены, что хотите удалить регион?',
    },
    deleted: {
        en: (r) => `Region ${r} was deleted`,
        ru: (r) => `Регион ${r} удалён`,
    },
};
