export default {
    currentStatus: {
        ru: 'Текущий статус заказа',
        en: 'Current status',
    },
    unavailable: {
        ru: 'Изменение статуса невозможно!',
        en: "Can't change status!",
    },
    changeConfirmation: {
        ru: 'Вы уверены, что хотите изменить статус?',
        en: 'Are you sure want to change status?',
    },
    emptyList: {
        ru: 'Нет доступных статусов',
        en: 'No available statuses',
    },
    promptIsRequired: {
        ru: (prompt) => `${prompt} обязательное поле`,
        en: (prompt) => `${prompt} is required`,
    },
    statusWasDeleted: {
        en: 'This status was deleted',
        ru: 'Этот статус был удалён',
    },
    moveToAnyAvailableStatus: {
        en: 'Move order to any available status',
        ru: 'Переместите заказ в любой доступный статус',
    },
};
